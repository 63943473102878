import Countries from "../countries/countries";

export default {
  "SeizureTypeEnum": {
    "IA": "Fokální jednoduchý záchvat",
    "IB": "Ohniskové komplexní záchvaty",
    "IC": "Ohniskové záchvaty se rozvíjejí v generalizované",
    "IIA": "Absence záchvatu",
    "IIB": "Mioklonický záchvat",
    "IIC": "Tonický záchvat",
    "IID": "Klonický záchvat",
    "IIE": "Tonicko-klonický záchvat",
    "IIF": "Atonický záchvat",
    "GEN": "Generovaný záchvat",
    "UNK": "Neznámý záchvat"
  },
  "SeizureTypeBriefEnum": {
    "IA": "Fokální jednoduchý záchvat",
    "IB": "Ohniskové komplexní záchvaty",
    "IC": "Ohniskové záchvaty se rozvíjejí v generalizované",
    "IIA": "Absence",
    "IIB": "Mioclonic",
    "IIC": "Tonikum",
    "IID": "Klonický",
    "IIE": "Tonicko-klonický",
    "IIF": "Atonický",
    "GEN": "Generováno",
    "UNK": "Neznámý"
  },
  "MedicalEventTypeEnum": {
    "SEIZURE": "Záchvat",
    "MENSTRUATION": "Menstruace",
    "INFECTION": "Infekce",
    "SLEEP_DEPRIVATION": "Nedostatek spánku",
    "FEVER": "Horečka",
    "OTHER": "jiný",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START": "Začátek těhotenství nebo období před těhotenstvím",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END": "Konec těhotenství nebo období před těhotenstvím"
  },
  "MessageTypeEnum": {
    "EMAIL": "e-mailem",
    "SMS": "sms",
    "SYS": "Systém",
    "CHAT": "povídat si"
  },
  "MessageStatusEnum": {
    "NEW": "Nový",
    "SENT": "odesláno",
    "READ": "číst"
  },
  "MessageMarkerEnum": {
    "MARKED": "výrazný",
    "NOT_MARKED": "není označeno"
  },
  "AffinityTypeEnumAdults": {
    "LEGAL_GUARDIAN": "zákonný zástupce",
    "CURATOR": "kurátor",
    "SPOUSE": "manžel"
  },
  "AffinityTypeEnumChildren": {
    "PARENT": "rodič",
    "ADOPTER": "adoter",
    "GUARDIAN": "jmenován opatrovníkem"
  },
  "VisitTypeEnumUndefined": "jiný",
  "DocumentTypeEnum": {
    "REFERRAL_GENERAL_PURPOSE": "Obecné doporučení",
    "REFERRAL_CLINIC": "Doporučení na specializované klinice",
    "REFERRAL_TO_HOSPITAL": "Doporučení do nemocnice",
    "REFERRAL_TO_PSYCHIATRIC_HOSPITAL": "Odeslání do psychiatrické léčebny",
    "REFERRAL_TO_SPECIALIST": "Doporučení specialistovi",
    "REFERRAL_TO_LAB": "Doporučení do laboratoře",
    "REFERRAL_TO_DIAGNOSTIC_LABORATORY": "Odeslání do diagnostické laboratoře",
    "CERTIFICATE": "Osvědčení",
    "CERTIFICATE_FOR_ZUS": "Osvědčení pro ZUS",
    "CERTIFICATE_OF_DISABILITY": "Osvědčení o zdravotním postižení",
    "HEALTH_CERTIFICATE": "Zdravotní průkaz",
    "INFORMATION_FOR_POZ_DOCTOR": "Informace pro lékaře POZ",
    "APPLICATION_FOR_REFUND": "Žádost o vrácení peněz",
    "APPLICATION_FOR_DRUG_REFUND": "Žádost o vrácení léku",
    "DEMAND_FOR_DRUG_FROM_ABROAD": "Poptávka po droze ze zahraničí",
    "DEMAND_FOR_FOODSTUFF_FROM_ABROAD": "Poptávka po potravině ze zahraničí",
    "OTHER": "Další dokumenty"
  },
  "MedicalResultDocumentTypeEnum": {
    "HOSPITAL_INFORMATION_CARD": "Informační karta pro nemocniční ošetření",
    "DISEASE_HISTORY": "Historie nemocí",
    "VIDEO_EVENT": "Video - událost",
    "LABORATORY_TEST_RESULT": "Výsledek laboratorního testu",
    "VISUAL_RESULT_MRI_FILE": "Soubor výsledků testu - magnetická rezonance",
    "VISUAL_RESULT_MRI_DESC": "Popis výsledku testu - magnetická rezonance",
    "VISUAL_RESULT_CT_FILE": "Soubor výsledků testu - počítačová tomografie",
    "VISUAL_RESULT_CT_DESC": "Popis výsledku testu - počítačová tomografie",
    "VISUAL_RESULT_EEG_FILE": "Soubor výsledků zkoušky - EEG",
    "VISUAL_RESULT_EEG_DESC": "Popis výsledku zkoušky - EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Další soubor s výsledky testu",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Další popis výsledku zkoušky",
    "PATIENT_CERTIFICATE": "Certifikát Neurosphera",
    "STATE_CERTIFICATE": "Státní osvědčení",
    "EEG_DESCRIPTION": "EEG popis",
    "OTHER": "jiný"
  },
  "DoctorTitle": {
    "DR_MED": "dr n. med.",
    "DR_HAB": "dr hab.",
    "DR_PROF": "prof dr hab.",
    "LEK_MED": "lek. med.",
    "TECHN": "techn.",
    "MGR": "Mgr",
    "DR": "Dr",
    "STUDENT": "student UM"
  },
  "PricelistItemGroupEnum": {
    "NORMAL_VISIT": "normální návštěva",
    "SOS_VISIT": "návštěva sos",
    "DIAGNOSTIC": "popis zkoušky",
    "OTHER": "jiný"
  },
  "PricelistItemTypeEnum": {
    "FIRST_TIME": "pierwszorazowa",
    "SUBSEQUENT": "kolejna",
    "OTHER": "inne"
  },
  "SexEnum": {
    "FEMALE": "ženský",
    "MALE": "mužský"
  },
  "SeizureFrequencyPeriodEnum": {
    "YEAR": "rok",
    "MONTH": "Měsíc",
    "DAY": "den"
  },
  "BloodType": {
    "TYPE_A": "A",
    "TYPE_B": "B",
    "TYPE_AB": "AB",
    "TYPE_0": "0"
  },
  "RhType": {
    "RH_PLUS": "RH +",
    "RH_MINUS": "RH-"
  },
  "PsychomotorDevelopmentOverallEnum": {
    "NORMAL": "Normální",
    "RETARDED": "Retardovaný",
    "VERY_RETARDED": "Velmi retardovaný",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "PsychomotorRetardationEnum": {
    "NO": "Ne",
    "LIGHT": "Světlo",
    "MODERATE": "Mírný",
    "SIGNIFICANT": "Významný"
  },
  "PsychomotorSpeechEnum": {
    "NORMAL": "Normální",
    "DISTURBED": "Narušený",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "PsychomotorWalkEnum": {
    "NORMAL": "Normální",
    "INCORRECT": "Nesprávný",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "PsychomotorSexEnum": {
    "NORMAL": "Normální",
    "LIBIDO_DISORDERS": "Poruchy libida",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "NystagmusEnum": {
    "NO": "Ne",
    "VESTIBULAR": "Vestibulární",
    "CNS": "CNS"
  },
  "VisionEnum": {
    "NORMAL": "Normální",
    "DEFECT_PLUS": "Vada vidění plus (+)",
    "DEFECT_MINUS": "Porucha vidění minus (-)",
    "DEFECT_OTHER": "Další vada zraku",
    "BLIND": "Slepý",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "HearingEnum": {
    "NORMAL": "Normální",
    "DISTURBED": "Narušený",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "SmokingEnum": {
    "NO": "Ne",
    "YES_IN_PAST": "Ano, v minulosti",
    "YES_NOW": "Ano, aktuálně",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "AlcoholEnum": {
    "NO": "Ne",
    "SOMETIMES_LITTLE": "Někdy málo",
    "SOMETIMES_A_LOT": "Někdy hodně",
    "OFTEN_LITTLE": "Často málo",
    "OFTEN_A_LOT": "Často hodně",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "NarcoticsEnum": {
    "NO": "Ne",
    "YES_IN_PAST": "Ano, v minulosti",
    "YES_NOW_RARELY": "Ano, aktuálně - příležitostně",
    "YES_NOW_OFTEN": "Ano, obecnie - často",
    "NOT_APPLICABLE": "Nelze použít"
  },
  "SeizuresCharacteristicsFrequencyEnum": {
    "ALWAYS": "Ano (1 záchvat) / Ano, vždy (100% záchvaty)",
    "VERY_OFTEN": "Ano, velmi často (75 - 99% záchvatů)",
    "OFTEN": "Ano, často (51-74% záchvatů)",
    "SOMETIMES": "Ano, někdy (~ 50% záchvatů)",
    "RARELY": "Ano, ale zřídka (25–49% záchvatů)",
    "VERY_RARELY": "Ano, ale extrémně vzácné (1 - 24% záchvatů)",
    "NEVER": "Ne (1 záchvat) / Ne, nikdy (0% záchvaty)",
    "NOT_KNOWN": "Neznámý"
  },
  "DrugHistoryTimeEnum": {
    "PAST": "Ano, v minulosti",
    "CURRENTLY": "Ano, aktuálně"
  },
  "DrugHistoryEffectEnum": {
    "REMISSION": "Remise",
    "BIG_IMPROVEMENT": "Vylepšení> 50%",
    "LITTLE_IMPROVEMENT": "Až 50% zlepšení",
    "NO_CHANGE": "Žádná změna",
    "LITTLE_DETERIORATION": "Mírné zhoršení",
    "BIG_DETERIORATION": "Výrazné zhoršení",
    "EXTREME_DETERIORATION": "Extrémní zhoršení / eliminace vedlejších účinků (např. Vyrážka)"
  },
  "SeizuresCharacteristicsDurationEnum": {
    "VERY_LONG": "Velmi dlouhá, více než 5 minut",
    "LONG": "Dlouhá, od 1 do 5 minut",
    "BIT_LONG": "30 až 60 sekund",
    "VARIABLE": "Zcela jiný, proměnlivý",
    "SHORT": "Krátce od několika do 30 sekund",
    "VERY_SHORT": "Velmi krátké, méně než 5 s",
    "ULTRA_SHORT": "Ultrakrátká, zlomek sekundy na sekundu",
    "NOT_KNOWN": "Nevím"
  },
  "UserAccountSettingCodeEnum": {
    "VISIT_CHANGED_BY_PATIENT": "Upozorní, když pacient změní čas návštěvy",
    "VISIT_APPOINTED_REMINDER": "Připomínky o návštěvách jmenovaných následující den",
    "VISIT_PLANNED_REMINDER": "Připomenutí návštěv naplánovaných na další den",
    "DUTY_PLANNED_REMINDER": "Připomenutí povinností naplánovaných na další den",
    "VISIT_CANCELED_BY_DOCTOR": "Upozorňuje, když lékař zruší návštěvu",
    "VISIT_CANCELLED_BY_PATIENT": "Upozorní, když pacient zruší návštěvu",
    "ADMIT_PATIENTS": "Viditelné pro pacienty v databázi lékařů",
    "MESSAGE_RECEIVED_BY_DOCTOR": "Upozornění, když pacient odešle zprávu",
    "PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR": "Upozornění, když pacient odešle žádost o předpis"
  },
  "VisitCancellationReason": {
    "INCONVENIENT_DATE": "Nevhodné datum",
    "DOCTOR_CHANGE": "Změna lékaře"
  },
  "CountryEnum": Countries.cs,
  "MedicalServiceSystemItemEnum": {
    "SOS_TELECONFERENCE": "SOS telekonference",
    "SOS_CHAT": "SOS chat"
  },
  "MedicalServiceEnum": {
    "VISIT_0": "Navštivte 0",
    "MIGRATION_VISIT": "Migrační návštěva",
    "VISIT_1": "Navštivte 1",
    "STANDARD_VISIT": "Standardní návštěva",
    "MESSAGE": "Zpráva",
    "PRESCRIPTION": "Předpis",
    "NEUROSURGEON_CONSULTATION": "Konzultace - neurochirurg",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultace - neuropsycholog",
    "NEUROLOGOPEDIST_CONSULTATION": "Konzultace - neurolog",
    "PSYCHIATRIST_CONSULTATION": "Konzultace - psychiatr",
    "EPILEPTOLOGIST_CONSULTATION": "Konzultace - epileptolog",
    "EEG_CONSULTATION": "Badanie EEG",
    "NEUROLOGIST_CONSULTATION": "Konzultace - neurolog",
    "DIETICIAN_CONSULTATION": "Konzultace - dietolog",
    "VNS_CONSULTATION": "návštěva VNS",
    "GENETICIST_CONSULTATION": "Konzultace - genetik",
    "ABSENT_CONSULTATION": "Chybějící konzultace",
    "METABOLIC_CONSULTATION": "Metabolické poradenství",
    "VEEG_ON_STANDBY_CONSULTATION": "VEEG vyšetření v bdělém stavu 1 - 6 h",
    "VEEG_DURING_SLEEP_CONSULTATION": "VEEG při vyšetření spánku 1 -6 hod",
    "VEEG_NIGHT_CONSULTATION": "VEEG noční vyšetření 12h",
    "VEEG_DAY_CONSULTATION": "24hodinové VEEG vyšetření 24h",
    "SLEEP_DISTURBANCE_CONSULTATION": "Poradenství při poruchách spánku",
    "CASE_CONFERENCE": "Lékařská případová konference",
    "VEEG_ON_STANDBY_CONSULTATION_1H": "VEEG vyšetření v bdělém stavu 1 h",
    "VEEG_ON_STANDBY_CONSULTATION_2H": "VEEG vyšetření v bdělém stavu 2 hodiny",
    "VEEG_ON_STANDBY_CONSULTATION_6H": "VEEG vyšetření v bdělém stavu 6 hodin",
    "VEEG_DURING_SLEEP_CONSULTATION_1H": "VEEG vyšetření ve spánku 1h",
    "VEEG_DURING_SLEEP_CONSULTATION_2H": "VEEG vyšetření ve spánku 2h",
    "VEEG_DURING_SLEEP_CONSULTATION_6H": "VEEG vyšetření ve spánku 6h"
  },
  "VisitTypeEnum": {
    "STATIONARY": "Stacionární",
    "REMOTE": "Dálkový",
    "ABSENT": "Chybí"
  },
  "VisitTypeDescriptionEnum": {
    "STATIONARY": "Návštěva se koná na ambulanci ve {place}. Během návštěvy se lékař s pacientem setká přímo. Na místě kontroluje aktuální dokumentaci pacienta. Pokud je vystaven recept - lékař vystaví recept na místě a odešle jej prostřednictvím SMS nebo jej vytiskne.",
    "REMOTE": "Online návštěva, kde se lékař a pacient vidí na obrazovce. Připojení probíhá přes Neuroterminal a nevyžaduje instalaci dalšího softwaru. Dokumentaci zasílá pacient elektronicky prostřednictvím neuroterminálu. Pokud je vyžadován předpis, lékař vystaví elektronický recept.",
    "ABSENT": "Absence konzultace je typ lékařské návštěvy, kterou provádí vybraný specialista bez přítomnosti pacienta. Absentující konzultace je zaměřena na rozbor zdravotnické dokumentace nebo doplňující vyšetření. Specialista na neurosféru provede analýzu dokumentace, provede příslušnou poznámku a doporučení pro pacienta."
  },
  "MedicalResultTypeEnum": {
    "VIDEO_EVENT": "Video",
    "LABORATORY_TEST_RESULT": "Laboratorní test",
    "VISUAL_RESULT_MRI_FILE": "Magnetická rezonance",
    "VISUAL_RESULT_MRI_DESC": "Magnetická rezonance",
    "VISUAL_RESULT_CT_FILE": "Počítačová tomografie",
    "VISUAL_RESULT_CT_DESC": "Počítačová tomografie",
    "VISUAL_RESULT_EEG_FILE": "EEG",
    "VISUAL_RESULT_EEG_DESC": "EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Další",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Další"
  },
  "DoctorTypeEnum": {
    "RESIDENT": "Klinický rezident",
    "SPECIALIST": "Klinický specialista",
    "EXPERT": "Lékařský partner",
    "CONSULTANT": "Klinický poradce",
    "ASSISTANT": "Asistent",
    "INTERNATIONAL_CONSULTANT": "Mezinárodní klinický konzultant",
    "PARTNER_CONSULTANT": "Partnerský konzultant"
  },
  "PatientTypeEnum": {
    "INFANT": "děti do 2 let",
    "CHILD": "děti od 2 do 12 let",
    "YOUTH": "mládež od 12 do 18 let",
    "ADULT": "dospělí od 18 do 65 let",
    "SENIOR": "senioři nad 65 let"
  },
  "HasEpilepsyEnum": {
    "NO": "Ne",
    "YES": "Ano",
    "NOT_KNOWN": "Neznámý"
  },
  "HealthSurveyPartEnum": {
    "PSYCHOMOTOR_DEVELOPMENT": "psychomotorický vývoj",
    "SEIZURE_TYPES": "typy záchvatů",
    "SEIZURES_HISTORY": "historie záchvatů",
    "SEIZURES_CHARACTERISTICS": "charakteristika záchvatů",
    "COMORBIDITIES": "komorbidity",
    "DRUGS_HISTORY": "léčba"
  },
  "TimeRangeEnum": {
    "1M": "1 měsíc",
    "3M": "3 měsíce",
    "6M": "6 měsíců",
    "1Y": "1 rok",
    "2Y": "2 roky",
    "3Y": "3 roky",
    "SINCE_BIRTH": "Od narození",
    "SINCE_FIRST_SEIZURE": "Od prvního záchvatu",
    "1H": "1 hodina",
    "2H": "2 hodiny",
    "3H": "3 hodiny",
    "4H": "4 hodiny",
    "5H": "5 hodin",
    "6H": "6 hodin"
  },
  "MedicalRequestStatusEnum": {
    "NEW": "Nový",
    "ACCEPTED": "Přijato",
    "DONE": "Hotovo",
    "REJECTED": "Zamítnuto",
    "CANCELLED": "Zrušeno"
  },
  "PersonalDocumentEnum": {
    "PASSPORT": "Cestovní pas",
    "DRIVING_LICENSE": "Řidičský prúkaz"
  },
  "NSPHSourceEnum": {
    "INTERNET_SEARCH": "vyhledávání na internetu",
    "SOCIAL_MEDIA": "Sociální média",
    "INTERNET_ARTICLE": "Internetový článek",
    "PRESS_ARTICLE": "Tiskový článek",
    "TV": "Televize",
    "FAMILY_FRIENDS": "Od rodiny nebo přátel",
    "DOCTOR": "Od doktora",
    "OTHER": "jiný"
  },
  "NFZBranchEnum": {
    "NFZ_01": "01 - Dolnoslezská pobočka Státního zdravotního fondu ve Vratislavi",
    "NFZ_02": "02 - Kujawsko-Pomorskie pobočka Národního fondu zdraví v Bydhošti",
    "NFZ_03": "03 - Lublinská pobočka Národního zdravotního fondu v Lublinu",
    "NFZ_04": "04 - Lubuski pobočka Národního fondu zdraví v Zielona Góra",
    "NFZ_05": "05 - Łódź Pobočka Národního zdravotního fondu v Lodži",
    "NFZ_06": "06 - Malopolská pobočka Národního zdravotního fondu v Krakově",
    "NFZ_07": "07 - Mazowiecki pobočka Národního fondu zdraví ve Varšavě",
    "NFZ_08": "08 - Opole Pobočka Národního zdravotního fondu v Opole",
    "NFZ_09": "09 - Podkarpatská pobočka Národního fondu zdraví v Rzeszówě",
    "NFZ_10": "10 - Podlaská pobočka Národního fondu zdraví v Białystoku",
    "NFZ_11": "11 - Pomořská pobočka Národního zdravotního fondu v Gdaňsku",
    "NFZ_12": "12 - Slezská pobočka Národního fondu zdraví v Katovicích",
    "NFZ_13": "13 - Świętokrzyski pobočka Národního fondu zdraví v Kielcích",
    "NFZ_14": "14 - Warmijsko-mazurská pobočka Národního fondu zdraví v Olsztyně",
    "NFZ_15": "15 - Velkopolská pobočka Národního fondu zdraví v Poznani",
    "NFZ_16": "16. - Západopomořanské pobočky Národního fondu zdraví ve Štětíně"
  },
  "VisitPackageEnum": {
    "START_PACKAGE": "Průzkum 0 + návštěva 1",
    "ADHD_PACKAGE": "ADHD balíček",
    "REGULAR_VISIT_EEG_PACKAGE": "Pravidelná návštěva + EEG",
    "MMPI2_PACKAGE": "Osobnostní test MMPI-2",
    "CBT_PACKAGE": "KBT terapie"
  },
  "MedicalServiceGroupEnum": {
    "VISIT_0": "Průzkum 0",
    "MIGRATION_VISIT": "Migrační návštěva",
    "VISIT_1": "Návštěva 1",
    "STANDARD_VISIT": "Standardní návštěva",
    "MESSAGE": "Zpráva",
    "PRESCRIPTION": "Předpis",
    "NEUROSURGEON_CONSULTATION": "Konzultace - neurochirurg",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultace - neuropsycholog",
    "NEUROLOGOPEDIST_CONSULTATION": "Konzultace - neurolog",
    "PSYCHIATRIST_CONSULTATION": "Konzultace - psychiatr",
    "EPILEPTOLOGIST_CONSULTATION": "Konzultace - epileptolog",
    "NEUROLOGIST_CONSULTATION": "Konzultace - neurolog",
    "DIETICIAN_CONSULTATION": "Konzultace - dietolog",
    "VNS_CONSULTATION": "návštěva VNS",
    "GENETICIST_CONSULTATION": "Konzultace - genetik",
    "ABSENT_CONSULTATION": "Chybějící konzultace",
    "METABOLIC_CONSULTATION": "Metabolické poradenství",
    "EEG_CONSULTATION": "EEG vyšetření",
    "SLEEP_DISTURBANCE_CONSULTATION": "Poradenství při poruchách spánku",
    "PARTNER_CONSULTATION": "Konzultace s partnerem",
    "ADHD_CONSULTATION": 'Koučovací sezení ADHD',
    "PSYCHOTHERAPY_CONSULTATION": 'Psychoterapeutické sezení'
  },
  "ShortTimeRangeEnum": {
    "h": "h",
    "m": "min"
  },
  "WellBeingEnum": {
    "WORST": "maximálně horší / špatný",
    "MUCH_WORSE": "mnohem horší",
    "BIT_WORSE": "trochu horší",
    "SAME": "stejný",
    "BIT_BETTER": "trochu lepší",
    "MUCH_BETTER": "mnohem lepší",
    "IDEAL": "perfektní, plné zdraví"
  },
  "CurrentTreatmentEnum": {
    "WORST": "úplně špatně, nejde to a nechci v tom pokračovat",
    "VERY_BAD": "není dobré, raději to změním",
    "BAD": "Není mi dobře, ale můžu pokračovat",
    "NO_CHANGE": "Necítím žádnou změnu",
    "BIT_BETTER": "to je v pořádku, po této léčbě se cítím trochu lépe",
    "MUCH_BETTER": "dochází ke zlepšení, léčba jednoznačně pomáhá",
    "IDEAL": "léčba je perfektní, plný úspěch"
  },
  "SideEffectsEnum": {
    "WORST": "jsou tak vážné, že v této léčbě pro jistotu nechci pokračovat",
    "BAD": "jsou značné a obtěžují mě, ale v případě potřeby mohu pokračovat",
    "BEARABLE": "jsou patrné, ale není tak špatné změnit léčbu",
    "MINIMAL": "to je v pořádku, vedlejší účinky jsou minimální, žádný vliv na mě"
  },
  "TreatmentEfficiencyEnum": {
    "IDEAL": "Perfektní",
    "GOOD": "Dobrý",
    "MEDIOCRE": "Průměrný",
    "BAD": "Špatný",
    "WORST": "Nejhorší je, že je potřeba změnit léčbu"
  },
  "TreatmentSafetyEnum": {
    "TOTAL": "Celkový",
    "ACCEPTABLE": "Přijatelný",
    "WEAK": "Slabý",
    "BAD": "Špatný",
    "WORST": "Vylučuje drogu, nutná změna"
  },
  "AdditionalIncidentsEnum": {
    "NEW_DISEASES": "Nové nemoci",
    "INJURIES": "Zranění",
    "HOSPITALIZATIONS": "Hospitalizace",
    "OTHER": "jiný",
    "NONE": "Žádný"
  },
  "SeizureCountEnum": {
    "MORE": "Více",
    "LESS": "Méně",
    "SAME": "Stejný",
    "NONE": "Žádný"
  },
  "SeizureEvaluationEnum": {
    "WORSE": "Horší",
    "BETTER": "Lehčí",
    "NONE": "Žádný"
  },
  "AllSeizureRegisteredEnum": {
    "TRUE": "Všechny jsem zaregistroval",
    "FALSE": "Nezaregistroval jsem je - udělám to teď"
  },
  "ExternalReferralTypeEnum": {
    "CLINIC": "do odborné ambulance",
    "LABORATORY": "do diagnostické laboratoře",
    "HOSPITAL": "pro nemocniční ošetření"
  },
  "CertificateTypeEnum": {
    "DRUGS_INTAKE": "Potvrzení do zdravotního střediska o užívaných lécích",
    "POSTPONE_VACCINATION": "Potvrzení o odložení očkování",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Potvrzení o absenci kontraindikace očkování",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Certifikát bez kontraindikací pro výkon",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Osvědčení o absenci kontraindikací k účasti na lekcích",
    "NO_CONTRAINDICATION_FOR_WORK": "Osvědčení, že neexistují žádné kontraindikace k provádění fyzické práce",
    "OTHER": "jiný"
  },
  "CertificateTypeDescriptionEnum": {
    "DRUGS_INTAKE": "Tímto potvrzuji, že pacient je v péči Centra pro léčbu epilepsie Neurosphera. Prosím o předepsání léků v místě bydliště. Pacient užívá následující léky",
    "POSTPONE_VACCINATION": "Tímto potvrzuji, že pacient je v péči Centra pro léčbu epilepsie Neurosphera. Žádám o odklad očkování do",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Tímto potvrzuji, že pacient je v péči Centra pro léčbu epilepsie Neurosphera. Pro povinné očkování nejsou žádné kontraindikace",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Tímto potvrzuji, že pacient je v péči Centra pro léčbu epilepsie Neurosphera. Při plánovaném výkonu nejsou žádné kontraindikace pro celkovou anestezii",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Tímto potvrzuji, že pacient je v péči Centra pro léčbu epilepsie Neurosphera. Účast na aktivitách nemá žádné kontraindikace",
    "NO_CONTRAINDICATION_FOR_WORK": "Tímto potvrzuji, že pacient je v péči Centra pro léčbu epilepsie Neurosphera. Neexistují žádné kontraindikace pro fyzickou práci.",
    "OTHER": "Tímto potvrzuji, že pacient je v péči Centra pro léčbu epilepsie Neurosphera."
  },
  "MessageVisitSummaryStatusEnum": {
    "NEW": "Nerealizováno",
    "PENDING": "Čekání na odpověď",
    "RESPONDED": "Odpovězeno",
    "DONE": "Hotovo",
    "UNIDIRECTIONAL": "Jednosměrný"
  },
  "SickLeaveTypeEnum": {
    "DISEASE": "Choroba",
    "CARE": "Péče o nemocné"
  },
  "SickLeaveIndicationEnum": {
    "SHOULD_LIE": "Mělo by se lhát",
    "CAN_WALK": "Může chodit"
  },
  "SickLeaveInsuredInEnum": {
    "ZUS": "ZUS",
    "KRUS": "KRUS",
    "OTHER_PL": "jiné v Polsku",
    "OTHER_COUNTRY": "v jiné zemi"
  },
  "SickLeaveStatusEnum": {
    "ISSUED": "Vydáno",
    "CANCELED": "Zrušeno"
  },
  "SurveyCompletedEnum": {
    "YES": "Ano",
    "NO": "Ne",
    "PARTIALLY": "Napůl hotovo"
  },
  "EegCompletedEnum": {
    "YES": "Ano",
    "NO": "Ne",
    "BEFORE_NEXT": "Udělám před návštěvou 1"
  },
  "DocplannerIssueTypeEnum": {
    "DP_VISIT_NOT_BOOKED": "Návštěva není rezervována v ZN",
    "DP_VISIT_NOT_CANCELED": "Návštěva v ZN zrušena",
    "DP_VISIT_NOT_MOVED": "Návštěva se v ZN nepřestěhovala",
    "NOT_ELIGIBLE_FOR_CONSULTATION": "Nový pacient - konzultace"
  },
  "DocplannerVisitStatusEnum": {
    "NEW": "Nový",
    "CANCELED_BY_SCHEDULER": "Nezaplaceno",
    "CANCELED_BY_PERSON": "Zrušeno",
    "SOLVED": "Vyřešeno",
    "PAID_NOT_DONE": "Zaplaceno",
    "DONE": "Hotovo"
  },
  "ReferralStatusEnum": {
    "NEW": "Nový",
    "CANCELLED": "Zrušeno",
    "DONE": "Hotovo"
  },
  "VisitCaseSolvedEnum": {
    "APPOINTMENT": "Jmenování",
    "RESIGNATION": "Rezignace",
    "OTHER": "jiný"
  },
  "MedicalServiceGroupConsultantEnum": {
    "NEUROSURGEON_CONSULTATION": "neurochirurg",
    "NEUROPSYCHOLOGIST_CONSULTATION": "neuropsycholog",
    "NEUROLOGOPEDIST_CONSULTATION": "neurologed",
    "PSYCHIATRIST_CONSULTATION": "psychiatr",
    "EPILEPTOLOGIST_CONSULTATION": "epileptolog",
    "NEUROLOGIST_CONSULTATION": "neurolog",
    "DIETICIAN_CONSULTATION": "dietetik",
    "VNS_CONSULTATION": "VNS konzultant",
    "GENETICIST_CONSULTATION": "genetik",
    "METABOLIC_CONSULTATION": "metabolický poradce",
    "EEG_CONSULTATION": "EEG/VEEG konzultant",
    "SLEEP_DISTURBANCE_CONSULTATION": "poradce pro poruchy spánku"
  },
  "VisitPaymentMarkingEnum": {
    "WAITING_FOR_PAYMENT": "Označeno k platbě",
    "PAYMENT_RECEIVED": "Označeno jako zaplacené"
  },
  "PaymentProviderEnum": {
    "PAYU": "PayU",
    "PAYNOW": "Zaplať teď"
  },
  "VisitPackageTemplateType": {
    "EPILEPSY": "Epilepsie",
    "CONSULTATION": "Konzultace"
  },
  "SexEnumAbbr": {
    "FEMALE": "F",
    "MALE": "M"
  },
  "TherapyTypeEnum": {
    "POLI": "politerapie",
    "MONO": "Monoterapie",
    "NO_ACTIVE": "Není aktivní terapie"
  },
  "ComorbidityTypeEnum": {
    "HEADACHES": "Bolesti hlavy",
    "PSYCHIATRIC": "Psychiatrická onemocnění",
    "NEUROLOGICAL": "Neurologická onemocnění",
    "INFECTIOUS": "Zánětlivá a infekční onemocnění",
    "CANCER": "Rakovinová onemocnění",
    "IMMUNE": "Nemoci krve a imunity",
    "ENDOCRINE": "Endokrinní a metabolická onemocnění",
    "EYE": "Oční choroby",
    "LARYNGOLOGICAL": "Laryngologická onemocnění",
    "HEART": "Nemoci srdce a oběhového systému",
    "RESPIRATORY": "Nemoci dýchacích cest",
    "DIGESTIVE": "Nemoci trávicího systému",
    "SKIN": "Kožní onemocnění",
    "BONE": "Nemoci kostí",
    "UROGENITAL": "Genitourinární onemocnění",
    "DEVELOPMENTAL_DEFECTS": "Vývojové, vrozené vady a chromozomální aberace",
    "INJURIES_POISONING": "Zranění a otravy",
    "SOMATIC": "Nemoci, které ovlivňují léčbu epilepsie"
  },
  "PatientAccountRemovalReason": {
    "NO_MEDICAL_SERVICE": "Nenašel jsem službu, o kterou mám zájem",
    "NO_DATES": "Nenašel jsem vhodný termín návštěvy",
    "PRICE": "Ceny služeb mi nevyhovují",
    "OTHER": "Ostatní"
  },
  "PrescriptionRejectedReasonEnum": {
    "VISIT_ONLY": "Recept na vybraný lék lze vystavit pouze při návštěvě",
    "INQUIRY_REQUIRED": "Recept na vybraný lék může být vystaven po seznámení se s aktuálním zdravotním stavem pacienta",
    "ALREADY_PRESCRIBED": "Dříve vystavený recept by měl odpovídat potřebám pacienta",
    "OTHER": "Ostatní"
  },
  "BirthTypeEnum": {
    "PHYSIOLOGICAL": "Fyziologické (přírodní síly)",
    "PLANNED_CESAREAN": "císařský řez (plánovaný)",
    "UNPLANNED_CESAREAN": "císařský řez (neplánovaný)",
    "VACUUM": "Síly / vakuum"
  },
  "PaymentRefundRestrictionsEnum": {
    "REFUND_100": "více než 7 dní před službou, 100% vrácení peněz",
    "REFUND_75": "75% refundace 6-5 dní před službou",
    "REFUND_50": "50% refundace 4-3 dny před službou",
    "REFUND_25": "Vrácení 25 % 2 dny před službou",
    "REFUND_0": "den před poskytnutím služby a v den poskytnutí služby bez náhrady"
  },
  "NeuroscreenDiagnosisEnum": {
    "HEADACHES": "Bolesti hlavy",
    "MOVEMENT_ISSUES": "Poruchy pohybu",
    "MEMORY_ISSUES": "Poruchy paměti",
    "EATING_ISSUES": "Poruchy příjmu potravy",
    "CONSCIOUSNESS_ISSUES": "Poruchy vědomí",
    "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Poruchy psychomotorického vývoje",
    "EPILEPSY": "Epilepsie a záchvaty",
    "SPINE_ISSUES": "Problémy s páteří",
    "MUTIPLE_SCLEROSIS": "Roztroušená skleróza",
    "DIZZINESS": "Závrať",
    "AUTISM": "Autistické spektrum",
    "OTHER_SYMPTOMS": "Jiné neurologické příznaky"
  },
  "NeuroscreenFrequencyEnum": {
    "ALWAYS": "Ano",
    "SOMETIMES": "Někdy",
    "RARELY": "Zřídka",
    "NEVER": "Nikdy"
  }
}